import React, { FC } from 'react'

import { Page } from '../components/layout/Page'

import { Section } from '../components/layout/Section'

const Home: FC = () => {
  return (
    <Page>
      <Section title="404">Not found :-(</Section>
    </Page>
  )
}

export default Home
